import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './igssOldForm';

export default class IgssOldFormCrear extends Component {
    render() {

        const {
            createOld,
            update,
        } = this.props;

        const {
            updateData,
            cargando,
            getAfiliationData
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid big-card-form w-100">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                          <div>
                              <div className="top-title">
                                  Crear Formulario IGSS (Utilizado)
                              </div>
                              <Form                                        
                                  onSubmit={createOld}
                              />
                          </div>                            
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}
