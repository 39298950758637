import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'
import { Link } from 'react-router-dom';
class IgssForms extends Component {
    componentWillMount() {
        this.props.listar()
    }
    componentWillUnmount() {
        this.props.resetStore();
    }

    render() {
        const {
            listar,
            
            detail,
            create,
            update,
            destroy,
        } = this.props;


        const {
            cargando,
            page,
            data,
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <div className='d-flex' style={{ width: "70%", alignItems: "center" }}>
                        <HeaderSimple texto={"Agregar formulario IGSS"} ruta={"/igss-forms/create"}/>
                        <Link className="btn btn-secondary m-3" to="/igss-forms/old-create" style={{ minWidth: '300px' }}>Registrar Formulario Utilizado</Link>
                    </div>
                    <div className="table-decoration">
                        <div className="top-title">
                            LISTADO DE FORMULARIOS IGSS
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                              tdStyle={BreakLine}
                              thStyle={BreakLine}
                              dataField="no"
                              dataSort
                            >Número</TableHeaderColumn>
                            <TableHeaderColumn
                              tdStyle={BreakLine}
                              thStyle={BreakLine}
                              dataField="formDate"
                              dataSort
                            >Fecha del formulario</TableHeaderColumn>
                            <TableHeaderColumn
                              tdStyle={BreakLine}
                              thStyle={BreakLine}
                              dataField="patient"
                              dataSort
                              dataFormat={(cell, row)=>{
                                return (
                                <React.Fragment>                                    
                                    {row.patient ?
                                        <span>{row.patient.first_name} {row.patient.last_name}</span>
                                    :
                                        <span>&nbsp;</span>
                                    }
                                </React.Fragment>                                    
                                )
                              }}                              
                            >Paciente</TableHeaderColumn>
                            <TableHeaderColumn
                              tdStyle={BreakLine}
                              thStyle={BreakLine}
                              dataField="procedure"
                              dataSort
                              dataFormat={(cell, row)=>{
                                return (
                                <React.Fragment>  
                                    {row.procedure ?                                  
                                        <span>{row.procedure.name} ({row.procedure.code})</span>
                                    :
                                        <span>&nbsp;</span>
                                    }
                                </React.Fragment>                                    
                                )
                            }}                              
                              >Procedimiento</TableHeaderColumn>
                            <TableHeaderColumn
                              tdStyle={BreakLine}
                              thStyle={BreakLine}
                              dataField="procedureDate"
                              dataSort
                            >Fecha del procedimiento</TableHeaderColumn>
                                                                                       
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/igss-forms', editar: '/igss-forms', eliminar: destroy })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default IgssForms;
