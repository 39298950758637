import React from 'react';
import { api } from 'api';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSelectCustom, dateInput } from 'Utils/renderField/renderField';



const getPatients = (search) => {
    return api.get("patients", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                const patients = [];
                data.results.forEach(x=>{
                    patients.push({ value: x.id, label: x.first_name + " " + x.last_name, afiliation: x.no_afiliacion })
                })
                console.log("patients in get patients...")
                return patients
            }
            else{
                return []
            }
        })
        .catch(e => {
            console.log("No se obtuvieron datos... ", e)
            return []
        })
}

/*
const getAppointments = (search) => {
    return api.get("appoitments", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                const appoitments = [];
                data.results.forEach(x=>{
                    appoitments.push({value: x.id, label: x.first_name + " " + x.last_name})
                })
                console.log("patients in get patients...")
                return appoitments;
            }
            else{
                return []
            }
        })
        .catch(e => {
            console.log("No se obtuvieron datos... ", e)
            return []
        })
}
*/

const getProcedures = (search) => {
    return api.get("igss-procedures", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                const procedures = [];
                data.results.forEach(x=>{                    
                    procedures.push({value: x.id, label: x.name + " " + `(${x.code})` })
                })
                console.log("patients in get patients...")
                return procedures;
            }
            else{
                return []
            }
        })
        .catch(e => {
            console.log("No se obtuvieron datos... ", e)
            return []
        })
}

const Form = props => {
    const {
        afiliation,
        setAfiliation,
        getAfiliationData,
        handleSubmit
    } = props;

    const view = window.location.href.includes("view");

    return(
        <form onSubmit={handleSubmit} className="py-2 px-5">            
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="m-0">No. Formulario</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="no" disabled={view} autofocus component={renderField} type="text" className="form-control"/>
                    </div>
                </div>                
                <div className="m-2"> 
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="person" className="">Buscar y seleccionar paciente</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="patient"
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            //component={renderSearchSelectPatient}
                            component={renderSearchSelect}
                            loadOptions={getPatients} 
                            className="no-border"
                            onChange={(e) => {                                
                                if(e.afiliation){
                                    setAfiliation(e.afiliation)
                                }else{
                                    setAfiliation(null)
                                }                                
                            }}
                        />
                    </div>
                    {afiliation && <span>No. de afiliación <strong>{afiliation}</strong></span>}
                </div>                
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="person" className="">Procedimiento</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field
                            name="procedure"
                            valueKey="value"
                            labelKey="label"
                            label="name"
                            //component={renderSearchSelectPatient}
                            component={renderSearchSelect}
                            loadOptions={getProcedures}
                            className="no-border"
                            onChange={(e) => {                             
                                //detailPatient(e.value);
                            }}
                        />
                    </div>
                </div>                
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="person" className="">Tipo de compra</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center"> 
                        <Field
                            name="typeOfPurchase"
                            labelKey="label"
                            valueKey="value"
                            component={renderSelectCustom}
                            className="form-control text-left"
                            options={[
                                {value: 1, label:'Baja Cuantia'},
                                {value: 2, label:'Contrato'},
                                {value: 3, label:'Compra directa'},
                                {value: 4, label:'Convenio'}
                            ]}
                        />
                    </div>                   
                </div>                           
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="person" className="">Origen de la solicitud</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center"> 
                        <Field
                            name="requestOrigin"
                            labelKey="label"
                            valueKey="value"
                            component={renderSelectCustom}
                            className="form-control text-left"
                            options={[
                                {value: 1, label:'Hospitalización'},
                                {value: 2, label:'Consulta externa'},
                                {value: 3, label:'Emergencia'}
                            ]}
                        />
                    </div>                   
                </div>                 
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="person" className="">Tipo de paciente</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center"> 
                        <Field
                            name="patientType"
                            labelKey="label"
                            valueKey="value"
                            component={renderSelectCustom}
                            className="form-control text-left"
                            options={[
                                {value: 1, label: 'af'},
                                {value: 2, label: 'be'},
                                {value: 3, label: 'bh'},
                                {value: 4, label: 'pi'},
                                {value: 5, label: 'jub'},
                                {value: 6, label: 'je'}
                            ]}
                        />
                    </div>                   
                </div>  
                {/*              
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="m-0">Código del tipo de paciente</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="patientTypeCode" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>                                         
                */}
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="birthdate" className="">Fecha del formulario</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="formDate" isDisabled={view} component={dateInput} type="text" className="form-control" />
                    </div>
                </div>
                <div className="m-2">
                    <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="birthdate" className="">Fecha del procedimiento</label>
                    </div>
                    <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="procedureDate" isDisabled={view} component={dateInput} type="text" className="form-control" />
                    </div>
                </div>                                
            </div>      
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/igss-forms">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
       </form>
    )
};

const IgssFormsForm = reduxForm({
    form: 'igssForms',
    validate: data => {
        return validate(data, {
            'no': validators.exists()('Campo Requerido'),
            'patient': validators.exists()('Campo Requerido'),
            'procedure': validators.exists()('Campo Requerido'),
            'typeOfPurchase': validators.exists()('Campo Requerido'),
            'requestOrigin': validators.exists()('Campo Requerido'),
            'patientType': validators.exists()('Campo Requerido'),
            'formDate': validators.exists()('Campo Requerido'),
            'procedureDate': validators.exists()('Campo Requerido')
        })
    }
})(Form);

 export default IgssFormsForm
