import React, { Component } from 'react';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './IgssFormsForm';

export default class IgssFormsCrear extends Component {

    state = {
        afiliation: null,
        editar: false,
        mensaje: 'Agregar Formulario IGSS',
        password: false,
    }

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.detail(this.props.match.params.id);
            this.setState({
                editar: true,
                mensaje: 'Editar Formulario IGSS'
            });
        }
    }

    verPass = () => {
        this.setState({
            password: !this.state.password
        })
    }

    showPatientSearch = () => {
        this.setState({
            search_patient: !this.state.search_patient,
        })
    }

    setAfiliation = (afiliation) => {
        this.setState({ afiliation })
    }

    render() {

        const {
            create,
            update,
        } = this.props;

        const {
            updateData,
            cargando,
            getAfiliationData
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid big-card-form w-100">
                    <div className="table-decoration">
                        <LoadMask loading={cargando} blur_1>
                            {
                                this.state.editar ?
                                    <div>
                                        <div className="top-title">
                                            Formulario IGSS
                                        </div>
                                        <Form
                                            afiliation={this.state.afiliation}
                                            setAfiliation={this.setAfiliation}
                                            getAfiliationData={getAfiliationData}
                                            onSubmit={update}
                                            updateData={updateData}
                                            showPatientSearch={this.showPatientSearch}
                                            editando={true}
                                        />
                                    </div>
                            :
                                <div>
                                    <div className="top-title">
                                        Crear Formulario IGSS
                                    </div>
                                    <Form
                                        afiliation={this.state.afiliation}
                                        setAfiliation={this.setAfiliation}
                                        getAfiliationData={getAfiliationData}
                                        onSubmit={create}
                                        showPatientSearch={this.showPatientSearch}
                                        mostrar_pass
                                    />
                                </div>
                            }
                        </LoadMask>
                    </div>
                </div>
            </div>
        )
    }
}
