import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/igssForms';
import { resetStore } from "../../../redux/modules/login";
import IgssOldFormCrear from './igssOldFormCrear';

const ms2p = (state) => {
    return {
        ...state.igss_forms,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(IgssOldFormCrear);
