import Table from '../Utils/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BreakLine } from '../Utils/tableOptions';
import HeaderSimple from '../Utils/Header/HeaderSimple';
import { activeFormatter }  from '../Utils/Acciones/Acciones'

class IgssProcedures extends Component {
    componentWillMount() {
        this.props.listar()
    }
    componentWillUnmount() {
        this.props.resetStore();
    }

    render() {
        const {
            listar,
            detail,
            create,
            update,
            destroy,
        } = this.props;


        const {
            cargando,
            page,
            data,
        } = this.props;

        return (
            <div className="content">
                <div className="m-auto row-fluid w-100">
                    <HeaderSimple texto={"Agregar procedimiento IGSS"} ruta={"/igss-procedures/create"}/>
                    <div className="table-decoration">
                        <div className="top-title">
                            LISTADO DE PROCEDIMIENTOS IGSS
                        </div>
                        <Table
                            onPageChange={listar}
                            data={data}
                            loading={cargando}
                            page={page}
                        >
                            <TableHeaderColumn
                              tdStyle={BreakLine}
                              thStyle={BreakLine}
                              dataField="name"
                              dataSort
                            >Nombre</TableHeaderColumn>
                            <TableHeaderColumn
                              tdStyle={BreakLine}
                              thStyle={BreakLine}
                              dataField="code"
                              dataSort
                            >Código</TableHeaderColumn>
                            <TableHeaderColumn
                              tdStyle={BreakLine}
                              thStyle={BreakLine}
                              dataField="price"
                              dataSort
                              dataFormat={(cell, row)=>{
                                return (
                                <React.Fragment>                                    
                                    <span>Q{cell}</span>
                                </React.Fragment>                                    
                                )
                              }}                               
                            >Precio</TableHeaderColumn>                            
                            <TableHeaderColumn
                                dataField="id"
                                isKey={true}
                                dataAlign="center"
                                dataFormat={activeFormatter({ ver: '/igss-procedures', editar: '/igss-procedures', eliminar: destroy })}>Acciones</TableHeaderColumn>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

export default IgssProcedures;
