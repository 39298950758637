import React from 'react';
import { api } from 'api';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderCurrency } from 'Utils/renderField/renderField';

const Form = props => {
    const {
        handleSubmit
    } = props;

    const view = window.location.href.includes("view");

    return(
        <form onSubmit={handleSubmit} className="py-2 px-5">
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="m-0">Nombre del procedimiento</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="name" disabled={view} autofocus component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="description" className="m-0">Código IGSS</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="code" disabled={view} component={renderField} type="text" className="form-control"/>
                    </div>
                </div>
            </div>
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="m-0">Precio</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="price" component={renderCurrency} type="text" className="form-control"/>
                    </div>
                </div>                    
            </div>                
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/igss-procedures">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
       </form>
    )
};

const IgssProceduresForm = reduxForm({
    form: 'igssProcedures',
    validate: data => {
        return validate(data, {
            'name': validators.exists()('Campo Requerido'),
            'code': validators.exists()('Campo Requerido'),
            'price': validators.exists()('Campo Requerido')
        })
    }
})(Form);

 export default IgssProceduresForm
