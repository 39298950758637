const url = "reporte/consolidado-gastos"; // Backend URL
import { api } from "api";
import Swal from "sweetalert2";
import { createActions, handleActions } from "redux-actions";
import moment from "moment";

const LOADER_REPORTE_CONSOLIDADO_GASTOS = "LOADER_REPORTE_CONSOLIDADO_GASTOS";
const SET_DATA_REPORTE_CONSOLIDADO_GASTOS =
    "SET_DATA_REPORTE_CONSOLIDADO_GASTOS";
const SET_PAGE_REPORTE_CONSOLIDADO_GASTOS =
    "SET_PAGE_REPORTE_CONSOLIDADO_GASTOS";
const SET_INICIAL_DATE_REPORTE_CONSOLIDADO_GASTOS =
    "SET_INICIAL_DATE_REPORTE_CONSOLIDADO_GASTOS";
const SET_FINAL_DATE_REPORTE_CONSOLIDADO_GASTOS =
    "SET_FINAL_DATE_REPORTE_CONSOLIDADO_GASTOS";
const SET_SEARCH_REPORTE_CONSOLIDADO_GASTOS =
    "SET_SEARCH_REPORTE_CONSOLIDADO_GASTOS";
const SET_PROVEEDOR_REPORTE_CONSOLIDADO_GASTOS =
    "SET_PROVEEDOR_REPORTE_CONSOLIDADO_GASTOS";
const SET_TIPO_GASTO_REPORTE_CONSOLIDADO_GASTOS =
    "SET_TIPO_GASTO_REPORTE_CONSOLIDADO_GASTOS";

const LOADER_EXCEL_REPORTE_CONSOLIDADO_GASTOS =
    "LOADER_EXCEL_REPORTE_CONSOLIDADO_GASTOS";

const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch({ type: LOADER_REPORTE_CONSOLIDADO_GASTOS, cargando: true });
        const buscadorFact = getStore().reporte_consolidado_gastos.buscadorFact;
        const proveedor = getStore().reporte_consolidado_gastos.proveedor.map(
            (item) => item.value
        );
        const tipo_gasto = getStore().reporte_consolidado_gastos.tipo_gasto.map(
            (item) => item.value
        );
        const initial_date = getStore().reporte_consolidado_gastos.initial_date;
        const final_date = getStore().reporte_consolidado_gastos.final_date;

        console.log("initial_date: ", initial_date);
        console.log("final_date: ", final_date);
        const params = {
            page,
            buscadorFact,
            proveedor,
            tipo_gasto,
            initial_date,
            final_date,
        };
        api.get(`${url}/getConsolidadoGastos`, params)
            .then((response) => {
                dispatch({
                    type: SET_DATA_REPORTE_CONSOLIDADO_GASTOS,
                    data: response,
                });
                dispatch({
                    type: SET_PAGE_REPORTE_CONSOLIDADO_GASTOS,
                    page,
                });
            })
            .catch((error) => {
                console.log("error: ", error);
                Swal.fire(
                    "Error",
                    "No se pudo conectar con el servidor :(",
                    "error"
                );
            })
            .finally(() => {
                dispatch({
                    type: LOADER_REPORTE_CONSOLIDADO_GASTOS,
                    cargando: false,
                });
            });
    };

const setLoaderExcel = (value) => (dispatch) => {
    dispatch({
        type: LOADER_EXCEL_REPORTE_CONSOLIDADO_GASTOS,
        cargandoExcel: value,
    });
};

const setSearch = (value) => (dispatch) => {
    dispatch({
        type: SET_SEARCH_REPORTE_CONSOLIDADO_GASTOS,
        buscadorFact: value,
    });
};

const setProveedor = (value) => (dispatch) => {
    dispatch({
        type: SET_PROVEEDOR_REPORTE_CONSOLIDADO_GASTOS,
        proveedor: value,
    });
};

const setTipoGasto = (value) => (dispatch) => {
    dispatch({
        type: SET_TIPO_GASTO_REPORTE_CONSOLIDADO_GASTOS,
        tipo_gasto: value,
    });
};

const setFinalDate = (value) => (dispatch) => {
    dispatch({
        type: SET_FINAL_DATE_REPORTE_CONSOLIDADO_GASTOS,
        final_date: value,
    });
};

const setInitialDate = (value) => (dispatch) => {
    dispatch({
        type: SET_INICIAL_DATE_REPORTE_CONSOLIDADO_GASTOS,
        initial_date: value,
    });
};

export const generarExcelConsolidadoGastos = () => (dispatch, getStore) => {
    dispatch(setLoaderExcel(true));
 
    let endpoint = `/api/${url}/export_to_excel_report_expenese/`;
    const regexFecha = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
 
    if (
        getStore().reporte_consolidado_gastos.initial_date &&
        getStore().reporte_consolidado_gastos.final_date &&
        regexFecha.test(getStore().reporte_consolidado_gastos.initial_date) &&
        regexFecha.test(getStore().reporte_consolidado_gastos.final_date)
    ) {
        endpoint += `?initial_date=${getStore().reporte_consolidado_gastos.initial_date}`;
        endpoint += `&final_date=${getStore().reporte_consolidado_gastos.final_date}`;
    } else {
        Swal.fire(
            "Error",
            "Debe seleccionar ambas fechas para generar el reporte",
            "error"
        );
        dispatch(setLoaderExcel(false));
        return;
    }

    const buscadorFact = getStore().reporte_consolidado_gastos.buscadorFact;
    const proveedor = getStore().reporte_consolidado_gastos.proveedor.map(
        (item) => item.value
    );
    const tipo_gasto = getStore().reporte_consolidado_gastos.tipo_gasto.map(
        (item) => item.value
    );
    const params = {
        buscadorFact,
        proveedor,
        tipo_gasto,
    };

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const element = params[key];
            if (element) {
                endpoint += `&${key}=${element}`;
            }
        }
    }

    fetch(endpoint, {
        method: "GET",
        headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
        },
    })
    .then((response) => {
        console.log("responsito :: ", response);
        Swal(
            'success',
            "Se está generando el archivo, puede ir a la sección de tareas para ver el estado del reporte",
            'success'
        );        
    })
    .catch(() => {
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );        
    })
    .finally(() => {
        //dispatch(setLoaderExcel(false));
    });

    /*
    fetch(endpoint, {
        method: "GET",
        headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
        },
    })
        .then((response) => {
            if (response.status === 200) {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = "Consolidado_de_gastos.xlsx";
                    a.click();
                });
            }
        })
        .catch(() => {
            NotificationManager.error(
                "Ocurrió un error al exportar el reporte",
                "ERROR",
                0
            );
        })
        .finally(() => {
            dispatch(setLoaderExcel(false));
        });
    */
};

export const actions = {
    listar,
    generarExcelConsolidadoGastos,
    setFinalDate,
    setInitialDate,
    setSearch,
    setProveedor,
    setTipoGasto,
};

export const reducer = {
    [LOADER_REPORTE_CONSOLIDADO_GASTOS]: (state, { cargando }) => {
        return { ...state, cargando };
    },
    [SET_DATA_REPORTE_CONSOLIDADO_GASTOS]: (state, { data }) => {
        return { ...state, data };
    },
    [SET_PAGE_REPORTE_CONSOLIDADO_GASTOS]: (state, { page }) => {
        return { ...state, page };
    },
    [SET_INICIAL_DATE_REPORTE_CONSOLIDADO_GASTOS]: (
        state,
        { initial_date }
    ) => {
        return {
            ...state,
            initial_date,
        };
    },
    [SET_FINAL_DATE_REPORTE_CONSOLIDADO_GASTOS]: (state, { final_date }) => {
        return {
            ...state,
            final_date,
        };
    },
    [SET_SEARCH_REPORTE_CONSOLIDADO_GASTOS]: (state, { buscadorFact }) => {
        return { ...state, buscadorFact };
    },
    [SET_PROVEEDOR_REPORTE_CONSOLIDADO_GASTOS]: (state, { proveedor }) => {
        return { ...state, proveedor };
    },
    [SET_TIPO_GASTO_REPORTE_CONSOLIDADO_GASTOS]: (state, { tipo_gasto }) => {
        return { ...state, tipo_gasto };
    },
    [LOADER_EXCEL_REPORTE_CONSOLIDADO_GASTOS]: (state, { cargandoExcel }) => {
        return { ...state, cargandoExcel };
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    /* El objeto data es el que se usa en el componente de la tabla
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    */
    data: [],
    tipo_gasto: [],
    proveedor: [],
    buscadorFact: "",
    initial_date: moment().date(1).format("DD/MM/YYYY"),
    final_date: moment().month(12).format("DD/MM/YYYY"),
    cargandoExcel: false,
};

export default handleActions(reducer, initialState);
