const url = 'igss-forms';
import { api } from 'api';
import Swal from 'sweetalert2';
import { push } from 'react-router-redux';
import { goBack } from 'react-router-redux';
import { initialize as initializeForm } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import moment from 'moment';

const LOADER_IGSS_FORMS = 'LOADER_IGSS_FORMS';
const SET_DATA_IGSS_FORMS = 'SET_DATA_IGSS_FORMS';
const SET_PAGE_IGSS_FORMS = 'SET_PAGE_IGSS_FORMS';
const SET_UPDATE_DATA_IGSS_FORMS = 'SET_UPDATE_DATA_IGSS_FORMS';
const SET_BUSCADOR_IGSS_FORMS = 'SET_BUSCADOR_IGSS_FORMS';
const SET_FILTRO_IGSS_FORMS = 'SET_FILTRO_IGSS_FORMS';

const PURCHASE_TYPE = [
    {value: 1, label:'Baja Cuantia'},
    {value: 2, label:'Contrato'},
    {value: 3, label:'Compra directa'},
    {value: 4, label:'Convenio'}
]

const REQUEST_ORIGIN =  [
    {value: 1, label:'Hospitalización'},
    {value: 2, label:'Consulta externa'},
    {value: 3, label:'Emergencia'}
]

const PATIENT_TYPE = [
    {value: 1, label:'af'},
    {value: 2, label:'be'},
    {value: 3, label:'bh'},
    {value: 4, label:'pi'},
    {value: 5, label:'jub'},
    {value: 6, label:'je'}
]

const getAfiliationData = (value) => (dispatch, getState) => {
    if(value && value.length === 13){   
        const url = `https://igss-service.onrender.com/getAfiliateData?number=${value}`; 

        fetch(url)
        .then(response => {
            // Verificar si la respuesta fue exitosa (código de estado 200-299)
            if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
            }
            // Parsear la respuesta JSON
            return response.json();
        })
        .then(data => {
            console.log("data response in igss ::: ", data);
            /*
            const state = getState().form.igssForms.values;
            if(data.nombre && data.apellidos){
                state.first_name = data.nombre;
                state.last_name = data.apellidos;
                dispatch(initializeForm('patients', state));
            } 
            */       
        })
        .catch(error => {
            // Manejo de errores
            console.error('Hubo un problema con la petición fetch:', error);
        });
    }
}

const listar = (page = 1) =>  (dispatch, getStore) => {
    dispatch({type: LOADER_IGSS_FORMS, cargando: true});
    const store = getStore();
    const search = store.igss_forms.buscador;
    const filtro = null
    let params = {page, search};
    if(filtro){
        params[''] = filtro
    }

    api.get(`${url}`, params).catch((error) => {
        dispatch({type: LOADER_IGSS_FORMS, cargando: false});
        Swal(
            'Error',
            error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).then((data) => {
        console.log("igss FORMS data then.... ", data)
        if(data){
            dispatch({type: SET_DATA_IGSS_FORMS, data});
            dispatch({type: SET_PAGE_IGSS_FORMS, page});
        }
        dispatch({type: LOADER_IGSS_FORMS, cargando: false});
    })
};

const detail = id => (dispatch, getState) =>{
    dispatch({type: LOADER_IGSS_FORMS, cargando: true});
    api.get(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_IGSS_FORMS, cargando: false});
        if(error.statusCode === 404){
            dispatch(push('/users'))
        }
    }).then((data) => {
        dispatch({type: LOADER_IGSS_FORMS, cargando: false});
        if(data){
            console.log("detail: ", data);
            data.patient = { value: data.patient.id, label: data.patient.first_name + ' ' + data.patient.last_name };
            data.procedure = { value: data.procedure.id, label: data.procedure.name + `(${data.procedure.code})`};
            
            data.patientType = PATIENT_TYPE[data.patientType - 1];
            data.requestOrigin = REQUEST_ORIGIN[data.requestOrigin - 1];
            data.typeOfPurchase = PURCHASE_TYPE[data.typeOfPurchase - 1];
            data.formDate = moment(data.formDate, "YYYY-MM-DD").format("DD-MM-YYYY");
            data.procedureDate = moment(data.procedureDate, "YYYY-MM-DD").format("DD-MM-YYYY");

            dispatch({type: SET_UPDATE_DATA_IGSS_FORMS, updateData: data});
            dispatch(initializeForm('igssForms', data))
        }
    })
}

const createOld = () => (dispatch, getStore) => {
    const formData = getStore().form.igssOldForm.values;
    dispatch({type: LOADER_IGSS_FORMS, cargando: true})

    const clearFormData = {
        ...formData,        
        formDate: moment().format("YYYY-MM-DD"),
        procedureDate: moment().format("YYYY-MM-DD")
    }

    api.post(`${url}`, clearFormData).then((data) => {
        console.log("Registrando formulario igss antiguo...")
        dispatch({type: LOADER_IGSS_FORMS, cargando: false})
        Swal('Éxito', 'Se ha creado el procedimiento del igss.', 'success')
        .then(() => {
            dispatch(push('/igss-forms'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_IGSS_FORMS, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_IGSS_FORMS, cargando: false})
    });
};

const create = () => (dispatch, getStore) => {
    const formData = getStore().form.igssForms.values;
    dispatch({type: LOADER_IGSS_FORMS, cargando: true})

    const clearFormData = {
        ...formData,
        patient: formData.patient.value,
        procedure: formData.procedure.value,
        typeOfPurchase: formData.typeOfPurchase.value,
        requestOrigin: formData.requestOrigin.value,
        patientType: formData.patientType.value,
        formDate: moment(formData.formDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        procedureDate: moment(formData.procedureDate, "DD-MM-YYYY").format("YYYY-MM-DD")
    }

    api.post(`${url}`, clearFormData).then((data) => {
        dispatch({type: LOADER_IGSS_FORMS, cargando: false})
        Swal('Éxito', 'Se ha creado el formulario del igss.', 'success')
        .then(() => {
            dispatch(push('/igss-forms'))
        })
    }).catch((error) => {
        dispatch({type: LOADER_IGSS_FORMS, cargando: false})
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, por favor vuelva a intentar.',
            'error'
        );
    }).finally(() => {
        dispatch({type: LOADER_IGSS_FORMS, cargando: false})
    });
};

const update = () => (dispatch, getStore) => {
    const formData = _.cloneDeep(getStore().form.igssForms.values);
    dispatch({type: LOADER_IGSS_FORMS, cargando: true});
    api.put(`${url}/${formData.id}`, formData)
    .then((data) => {
        dispatch({type: LOADER_IGSS_FORMS, cargando: false});
        if(data){
            Swal(
                'Éxito',
                'Datos editados correctamente.',
                'success'
            ).then(() => {
                dispatch(push('/igss-forms'));
            })
        }
    })
    .catch((error) =>{
        dispatch({type: LOADER_IGSS_FORMS, cargando: false});
        Swal(
            'Error',
             error.detail || 'Ha ocurrido un error, verifique los datos.',
            'error'
        )
        if(error.statusCode === 404){
            dispatch(push('/igss-FORMS/create'));
        }
    })
}

const destroy = (id) => (dispatch, getStore) => {
    dispatch({type: LOADER_IGSS_FORMS, cargando: true});
    api.eliminar(`${url}/${id}`).catch((error) => {
        dispatch({type: LOADER_IGSS_FORMS, cargando: false});
        Swal('Error', 'No se ha logrado borrar el registro.', 'error')
            .then(() => {
                dispatch(listar(1));
            })
    }).then((data) => {
        dispatch({type: LOADER_IGSS_FORMS, cargando: false});
        Swal('Éxito', 'Se ha eliminado correctamente.', 'success')
            .then(() => {
                dispatch(listar(1));
            })
    })
}

const search = (search) => (dispatch) => {
    dispatch({type: SET_BUSCADOR_IGSS_FORMS, buscador: search});
    dispatch(listar(1));
};

const filtro = (filtro) => (dispatch, getStore) => {
    if (filtro == 0){
        filtro = null
    }
    dispatch({type: SET_FILTRO_IGSS_FORMS, filtro_rol: filtro});
    dispatch(listar(1));
};

export const actions = {
    listar,
    getAfiliationData,
    create,
    createOld,
    update,
    detail,
    destroy,
    search,
    filtro,
};

export const reducer = {
    [LOADER_IGSS_FORMS]: (state, { cargando }) => {
        return {...state, cargando }
    },
    [SET_DATA_IGSS_FORMS]: (state, { data }) => {
        return {...state, data }
    },
    [SET_PAGE_IGSS_FORMS]: (state, { page }) => {
        return {...state, page }
    },
    [SET_UPDATE_DATA_IGSS_FORMS]: (state, { updateData }) => {
        return {...state, updateData }
    },
    [SET_BUSCADOR_IGSS_FORMS]: (state, { buscador }) => {
        return {...state, buscador }
    },
    [SET_FILTRO_IGSS_FORMS]: (state, { filtro_rol }) => {
        return {...state, filtro_rol }
    },
};

export const initialState = {
    cargando: false,
    page: 1,
    data: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    buscador: '',
    filtro_roles: null,
    updateData: {},
};

export default handleActions(reducer, initialState)
