import React from 'react';
import { api } from 'api';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSearchSelect, renderSelectCustom, dateInput } from 'Utils/renderField/renderField';

const Form = props => {
    const {
        getAfiliationData,
        handleSubmit
    } = props;

    const view = window.location.href.includes("view");

    return(
        <form onSubmit={handleSubmit} className="py-2 px-5">            
            <div className="px-md-5 d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                <div className="m-2">
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <label htmlFor="name" className="m-0">No. Formulario</label>
                    </div>
                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                        <Field name="no" disabled={view} autofocus component={renderField} type="text" className="form-control"/>
                    </div>
                </div>                
            </div>    
            <div className="row-fluid">
                <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary m-3" to="/igss-forms">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" className="btn btn-primary m-3">Guardar</button>)}
                </div>
            </div>
       </form>
    )
};

const IgssOldForm = reduxForm({
    form: 'igssOldForm',
    validate: data => {
        return validate(data, {
            'no':validators.exists()('Campo Requerido')
        })
    }
})(Form);

 export default IgssOldForm
