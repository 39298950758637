import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/igssProcedures';
import IgssProceduresCrear from './IgssProceduresCrear';
import { resetStore } from "../../../redux/modules/login";

const ms2p = (state) => {
    return {
        ...state.igss_procedures,
    };
};

const md2p = { ...actions, resetStore };

export default connect(ms2p, md2p)(IgssProceduresCrear);
